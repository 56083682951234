import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'


import theme from 'settings/theme'

import { Menu } from 'antd'

import {
    AppstoreOutlined,
    BarcodeOutlined,
    MailOutlined,
    QuestionCircleOutlined,
    UserOutlined,
    
    
} from '@ant-design/icons'

const { SubMenu } = Menu


type Props = {
    authIsAdmin: boolean,
    inlineCollapsed?: boolean,
    collapsedWidth?: number,
    closeMenu?: () => void,
    selectedKeys: string[]
}

const Component: React.FC<Props> = (props) => {

    return (

        <StyledComponent className="mainMenu">

            <Menu
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                inlineCollapsed={props.inlineCollapsed}
                collapsedWidth={props.collapsedWidth}
                selectedKeys={props.selectedKeys}
            >



                <Menu.Item key="dashboard" icon={<AppstoreOutlined />}>
                    <Link to={`/`} onClick={props.closeMenu}>
                        Dashboard
                    </Link>
                </Menu.Item>

                <Menu.Item key="products" icon={<BarcodeOutlined />}>
                    <Link to={`/products`} onClick={props.closeMenu}>
                        Product Catalogue
                    </Link>
                </Menu.Item>

                <Menu.Item key="account" icon={<UserOutlined />}>
                    <Link to={`/account`} onClick={props.closeMenu}>
                        Your Account
                    </Link>
                </Menu.Item>

                <Menu.Item key="faq" icon={<QuestionCircleOutlined />}>
                    <Link to={`/faq`} onClick={props.closeMenu}>
                        F.A.Q
                    </Link>
                </Menu.Item>

                <Menu.Item key="contact" icon={<MailOutlined />}>
                    <Link to={`/contact`} onClick={props.closeMenu}>
                        Contact Support
                    </Link>
                </Menu.Item>

                {props.authIsAdmin &&
        
                    <SubMenu key="admin" icon={<UserOutlined />} title="Users">
                        <Menu.Item key="adminUsersView">
                            <Link to={`/admin/users`} onClick={props.closeMenu}>
                                View All Users
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="adminUsersAdd">
                            <Link to={`/admin/add-user`} onClick={props.closeMenu}>
                                Add a New User
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                
                }

            </Menu>  
            
        </StyledComponent>

    )

}


export default Component




const StyledComponent = styled.div`

    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
        border-right: none;
    }
    

    .ant-menu-inline .ant-menu-item, 
    .ant-menu-inline .ant-menu-submenu-title{
        width: 100%;
    }

    .ant-menu-item-selected{
        color: ${theme.colors.brandText};
    }

    .ant-menu-item-selected,
    .ant-menu-item-active
    {

        color: ${theme.colors.brandText};

        a, a:hover, .anticon{
            color: ${theme.colors.brandText};
        }

    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, 
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active{
        background-color: ${theme.colors.pageBackground};
    }

    .ant-menu-vertical .ant-menu-item::after, 
    .ant-menu-vertical-left .ant-menu-item::after, 
    .ant-menu-vertical-right .ant-menu-item::after, 
    .ant-menu-inline .ant-menu-item::after{
        border-right: 3px solid ${theme.colors.brand};
    }


`