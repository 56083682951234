import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import config from 'settings/config'

import { selectAuthUser } from 'store/auth'
import { setOrder, setIsInitialized as setBasketIsInitialized } from 'store/basket'

import useApi  from 'hooks/useApi'

type Props = {}

// Component
const Component: React.FC<Props> = (props) => {

    const dispatch = useDispatch()
    const authUser: User = useSelector(selectAuthUser)

    // Data fetching
    const { 
        call: callGet,
        data: initData, 
    }: UseApiReturn<InitData> = useApi({ 
        asyncFn: (callConfig) => axios.get(`${config.apiUrl}/init`, callConfig),
        defer: true, 
        requiresAuth: true 
    })

    // Load Data
    useEffect(() => {
        if(authUser?.user_id){
            callGet()
        }
    }, [authUser?.user_id, callGet])



    // Data received
    useEffect(() => {
        if(initData){
            dispatch(setOrder(initData.basket))
            dispatch(setBasketIsInitialized(true))
        }
        
    }, [initData, dispatch])


    // Wrapper Component
    return <>{props.children}</>

}


export default Component