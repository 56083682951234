import { createGlobalStyle } from 'styled-components'

import theme from 'settings/theme'

// Layout Styles
const Styles = createGlobalStyle`

    a{
        text-decoration: none;
    }


    .ant-layout{
        background-color: ${theme.colors.pageBackground};
    }

    .ant-layout-header{
        background-color: ${theme.colors.pageBackground};
        h2 {line-height: auto;}
    }

    .ant-layout-footer{
        background-color: ${theme.colors.pageBackground};
    }


    /* Tables */
    .ant-table .ant-table-thead th{
        font-weight: bold;
    }

    .ant-table-content{
    }



    /* Forms */
    .ant-form-item-explain{
        padding-top: 4px;
        min-height: 36px;
    }

    input.ant-input{
        box-shadow: none;
    }

    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused
    {
        border-color: ${theme.colors.brand};
        box-shadow: 0 0 0 2px rgb(142 198 65 / 20%);
        background-color: white;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${theme.colors.brand};
        border-color: ${theme.colors.brand};
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
    .ant-checkbox:hover .ant-checkbox-inner, 
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
        border-color: ${theme.colors.brand};
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: ${theme.colors.brand};
        box-shadow: 0 0 0 2px rgb(142 198 65 / 20%);
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: rgba(239, 247, 229, 1) !important;
    }



    /* Steps */
    .ant-steps-item-icon .ant-steps-icon, 
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: ${theme.colors.brand};
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background-color: ${theme.colors.brand};
    }


    /* Breadcrumb */
    .ant-breadcrumb a:hover {
        color: ${theme.colors.brand};
    }


    /* Menus */
    .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
        color: ${theme.colors.brand};
    }

    .ant-menu-vertical .ant-menu-item::after, 
    .ant-menu-vertical-left .ant-menu-item::after, 
    .ant-menu-vertical-right .ant-menu-item::after, 
    .ant-menu-inline .ant-menu-item::after{
        color: ${theme.colors.brand};
    }

    .ant-menu-item:hover, 
    .ant-menu-item-active, 
    .ant-menu:not(.ant-menu-inline) 
    .ant-menu-submenu-open, 
    .ant-menu-submenu-active, 
    .ant-menu-submenu-title:hover {
        color: ${theme.colors.brand};
        background-color: rgba(239, 247, 229, 1) !important;
    }

    .ant-menu-submenu-selected{
        color: ${theme.colors.brand};
    }

    .ant-menu-submenu-arrow{
        &::before, &::after{
            background: ${theme.colors.brand} !important;
        }
    }

    .ant-menu-item a:hover {
        color: ${theme.colors.brand};
    }

    .ant-menu-item:active, .ant-menu-submenu-title:active,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        color: ${theme.colors.brand};
        background: white;
    }


    /* Buttons */
    .rm-button{

        display: inline-flex;
        align-items: center;
        border: none;
        transition: all 0.15s ease-out;

        .anticon{
            height: 16px;
        }

        &.rm-button--icon-right{

            flex-direction: row-reverse;

            > .anticon + span{
                margin-left: 0;
                margin-right: 10px;
            }

        }

        &:hover, &:focus{
            filter: brightness(120%);
        }

        &:active{
            filter: brightness(90%);
        }

    }

    

    .rm-button--primary{

        color: white;

        &:not(:hover), &:hover, &:focus &:hover.active{
            color: white;
            background-color: ${theme.colors.brand};
        }

        &[disabled]{
            background-color: ${theme.colors.brand} !important;
        }

        &.rm-button--ghost{

            color: ${theme.colors.brand} !important;
            border: solid 1px #d9d9d9;
            background: white;

            &:hover, &:focus, &:active{
                color: rgba(66, 97, 24, 1) !important;
                border: solid 1px ${theme.colors.brand};
            }

        }

    }

    .rm-button--secondary{

        color: white;

        &:not(:hover), &:hover, &:focus &:hover.active{
            color: white;
            background-color: rgba(138, 119, 101, 1);
        }

        &[disabled]{
            background-color: rgba(138, 119, 101, 1) !important;
        }
        
    }

    .rm-button--grey{

        color: white;

        &:not(:hover), &:hover, &:focus &:hover.active{
            color: white;
            background-color: rgba(150, 150, 150, 1);
        }

        &[disabled]{
            background-color: rgba(150, 150, 150, 1) !important;
        }

    }

    

    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
        color: white;
        opacity: 0.5;

        &:hover, &:focus, &:active{
            filter: brightness(100%);
        }

    }


`

export default Styles

