
const typography = {
    fontFamilyDefault: '"Comfortaa", Arial, Helvetica, sans-serif',
    fontFamilySecondary: '"TexGyreAdventor", Arial, Helvetica, sans-serif'
}

const colors = {

    brand: 'rgba(142, 198, 65, 1)',
    brandText: 'rgba(86, 140, 13, 1)',

    black: 'rgba(0, 0, 0, 1)',    
    white: 'rgba(255, 255, 255, 1)',
    green: 'rgba(67, 192, 30, 1)',
    red: 'rgba(243, 51, 77, 1)',

    // Text
    text: 'rgba(0, 0, 0, 1)',

    pageBackground: 'rgba(244, 247, 240, 1)'

}

const spacing = {
    default: '16px',
    paddingHorizontal: ['20px', '30px', '30px', '40px', '40px', '40px'],
    paddingVertical: ['20px', '30px', '30px', '40px', '40px', '40px'],
    spacingHorizontal: ['20px', '30px', '30px', '40px', '40px', '40px'],
    spacingVertical: ['20px', '30px', '30px', '40px', '40px', '40px'],
}

const breakpoints = [480, 576, 768, 992, 1200, 1600];


const bp = (index) => {
    return breakpoints[index-2] + 'px'
}

export {bp}

const exports = {
    typography, 
    colors, 
    spacing, 
    breakpoints
}

export default exports