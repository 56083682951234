import React from 'react'
import styled from 'styled-components'

import theme from 'settings/theme'

import { Grid } from 'antd'

import {
    Pulse
} from 'better-react-spinkit'

const { useBreakpoint } = Grid


type Props = {
    test?: string
}

const Component: React.FC<Props> = () => {

    const screens = useBreakpoint()

    return (
        <StyledComponent className="loader">
            <Pulse color={theme.colors.brand} size={screens.md ? 40 : 32} />
        </StyledComponent>
    )

}


export default Component


const StyledComponent = styled.div`


`