import { createGlobalStyle } from 'styled-components'

// Modal Styles
const ModalStyles = createGlobalStyle`

    .ant-modal-title{
        font-size: 20px;
        font-weight: bold;
    }

`

export default ModalStyles