import React from 'react'
import styled from 'styled-components'

import Loader from 'components/loader'


type Props = {
    style?: React.CSSProperties
}

const Component: React.FC<Props> = (props) => {

    return (
        <StyledComponent className="loaderCover" style={props.style}>
            <Loader />
        </StyledComponent>
    )

}


export default Component


const StyledComponent = styled.div`

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: all;
    background-color: rgba(255, 255, 255, 0.6);

`