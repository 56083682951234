import { createGlobalStyle } from 'styled-components'

import {bp} from 'settings/theme'

// Typography Styles
const TypographyStyles = createGlobalStyle`

    body{
        color: black;
        font-family: 'Catamaran', sans-serif;
        font-size: 14px;
    }

    button{
        font-family: 'Catamaran', sans-serif;
    }


    @media (min-width: ${bp(3)}) {
        
        body{
            font-size: 16px;
        }
        

    }
    

`

export default TypographyStyles

