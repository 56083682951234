import React from 'react'

import {
    Switch,
    Route
} from "react-router-dom"

import MainLayout from 'components/layout/mainLayout'
import LoaderCover from 'components/loaderCover'


const DashboardPage = React.lazy(() => import('pages/dashboard/dashboard'))
const ProductsPage = React.lazy(() => import('pages/products'))
const AccountPage = React.lazy(() => import('pages/account'))
const ChangePasswordPage = React.lazy(() => import('pages/changePassword'))
const ContactPage = React.lazy(() => import('pages/contact'))
const FaqPage = React.lazy(() => import('pages/faq'))
const LoginPage = React.lazy(() => import('pages/login'))
const ResetPasswordPage = React.lazy(() => import('pages/resetPassword'))
const RegisterPage = React.lazy(() => import('pages/register'))

const AdminRoutes = React.lazy(() => import('routes/admin'))
const BasketRoutes = React.lazy(() => import('pages/basket/basketLayout'))


type Props = {}

const Component: React.FC<Props> = (props) => {

    return (
        
        <React.Suspense fallback={<LoaderCover />}>
            <Switch>

                <Route exact path="/register">
                    <RegisterPage />
                </Route>

                <MainLayout>

                    <Route exact path="/">
                        <DashboardPage />
                    </Route>

                    <Route exact path="/products">
                        <ProductsPage />
                    </Route>

                    <Route exact path="/account">
                        <AccountPage />
                    </Route>

                    <Route exact path="/change-password">
                        <ChangePasswordPage />
                    </Route>

                    <Route exact path="/faq">
                        <FaqPage />
                    </Route>

                    <Route exact path="/contact">
                        <ContactPage />
                    </Route>

                    <Route exact path="/login">
                        <LoginPage />
                    </Route>

                    <Route exact path="/reset-password">
                        <ResetPasswordPage />
                    </Route>

                    <Route path="/basket">
                        <BasketRoutes />
                    </Route>

                    <Route path="/admin">
                        <AdminRoutes />
                    </Route>

                </MainLayout>

            </Switch>
        </React.Suspense>

    )

}


export default Component