import React from 'react';

import { StoreProvider } from 'store/index'

import styled from 'styled-components'
import GlobalStyles from 'styles/global'
import 'antd/dist/antd.css';

import {
    BrowserRouter as Router
} from "react-router-dom"

import Startup from 'startup'

import Routes from 'routes'


// Viewport stuff - TODO: Move this

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// We listen to the resize event
window.addEventListener('resize', () => {
	// We execute the same script as before
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`)
});


function App() {

	return (
        <StoreProvider>
            <Startup>
                <StyledApp>

                    <GlobalStyles />

                    <Router>
                        
                        <Routes />

                    </Router>
                    

                </StyledApp>
            </Startup>
        </StoreProvider>
	)
}

export default App


const StyledApp = styled.div`

	height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

`
