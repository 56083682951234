import React from 'react'

import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"

import authSlice from 'store/auth'
import basketSlice from 'store/basket'
import loadingSlice from 'store/loading'
import masterSlice from 'store/master'


const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        basket: basketSlice.reducer,
        loading: loadingSlice.reducer,
        master: masterSlice.reducer
    }
})


// Component
const StoreProvider = (props) => {

    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    )

}


export {StoreProvider}

export type RootState = ReturnType<typeof store.getState>

export default store