
let apiUrl = 'https://api.portal.multmark.co.uk'

let localApiPort = `30100`
let externalIp = `82.0.227.132`

// Dev mode

// Localhost
if(typeof window !== 'undefined' && document.URL.indexOf('localhost') > -1){
    apiUrl = `http://localhost:${localApiPort}`
}

// Local network
if(typeof window !== 'undefined' && document.URL.indexOf('192.168') > -1){
    apiUrl = `http://${externalIp}:${localApiPort}`
}


// Local Network with Hosts file
else if(typeof window !== 'undefined' && document.URL.indexOf('localmm.com') > -1){ 
    apiUrl = `http://api.localmm.com:${localApiPort}`
}


// Ngrok
else if(typeof window !== 'undefined' && document.URL.indexOf('ngrok') > -1){
    apiUrl = `https://mmportalapi.ngrok.io` // Don't need to add port because ngrok adds it when forwarding to local machine
}



const config = {

    apiUrl,

    logging: {
        groups: {
            // auth: {
            //     label: 'Auth'
            // },
            // data: {
            //     label: 'Data'
            // },
            // search: {
            //     label: 'Search'
            // }
        }
    },

    cloudinary: {
        subdomain: 'mmportal'
    }

}

export default config