import React, {useEffect} from 'react'
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import masterStore, {selectDrawerMenuIsOpen, selectMainMenuIsCollapsed, selectActiveMenuItem}  from 'store/master'
import {selectAuthIsAdmin}  from 'store/auth'

import LayoutHeader from 'components/layout/header'
import MainMenu from 'components/layout/mainMenu'
import LoginModal from 'components/layout/loginModal'

import {bp} from 'settings/theme'

import { Layout, Drawer, Grid } from 'antd'


const { Sider, Content } = Layout
const { useBreakpoint } = Grid


type Props = {}

type StyledProps = {
    mainMenuIsCollapsed: boolean
}

const Component: React.FC<Props> = (props) => {

    const screens = useBreakpoint()
    const dispatch = useDispatch()

    const mainMenuIsCollapsed = useSelector(selectMainMenuIsCollapsed)
    const drawerMenuIsOpen = useSelector(selectDrawerMenuIsOpen)
    const activeMenuItem = useSelector(selectActiveMenuItem)
    

    const authIsAdmin = useSelector(selectAuthIsAdmin)

    
    // Collapse main menu when medium breakpoint is hit
    useEffect(() => {
        dispatch(masterStore.actions.setMainMenuIsCollapsed(!screens.lg))
    }, [screens.lg, dispatch])

    // Close drawer menu when medium breakpoint is hit
    useEffect(() => {

        if(screens.md){
            dispatch(masterStore.actions.setDrawerMenuIsOpen(false))
        }

    }, [screens.md, dispatch])


    return (
        <StyledComponent mainMenuIsCollapsed={mainMenuIsCollapsed} className="mainLayout">

            <Layout className="mainLayout-outer">
                
                {screens.md &&
                    <Sider 
                        width={250} 
                        theme="light"
                        trigger={null} 
                        collapsible 
                        collapsed={mainMenuIsCollapsed}
                        breakpoint="lg"
                        className="mainLayout-sidebar"
                    >

                        <div className="logo">
                            <div className="logo-inner">
                                <img src="/logo.png" alt="logo" />
                            </div>
                        </div>

                        <MainMenu 
                            authIsAdmin={authIsAdmin}
                            inlineCollapsed={true} collapsedWidth={80} selectedKeys={[activeMenuItem]} 
                        />

                    </Sider>
                }

                <Layout>
                    
                    <LayoutHeader />

                    <Content className="mainLayout-content">

                        {props.children}

                    </Content>

                </Layout>

    
                
            </Layout>

            <Drawer
                placement="left"
                closable={true}
                visible={drawerMenuIsOpen}
                onClose={() => dispatch(masterStore.actions.setDrawerMenuIsOpen(false))}
                bodyStyle={{padding:'50px 0 0 0'}}
            >
                
                <MainMenu 
                    authIsAdmin={authIsAdmin} 
                    closeMenu={() => dispatch(masterStore.actions.setDrawerMenuIsOpen(false))} 
                    selectedKeys={[activeMenuItem]} 
                />

            </Drawer>

            <LoginModal />
            
            
        </StyledComponent>
    )

}


export default Component





const StyledComponent = styled.div<StyledProps>`

    .mainLayout-outer{
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }

    .mainLayout-sidebar{
        position: relative;
        z-index: 2;
        box-shadow: 4px 0 16px rgba(0,0,0,0.1);
    }

    .mainLayout-content{

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        position: relative;

        > *{
            flex: 1 0 auto;
        }

    }


    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;
        margin-bottom: 10px;
        padding: ${props => props.mainMenuIsCollapsed ? '12px' : '16px'};
        box-shadow: 0 2px 6px 0px rgba(0,0,0,0.1);
        transition: all 0.15s ease-out;

        .logo-inner{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            background-color: rgba(142, 198, 65, 1);
        }

        img{
            width: 40px;
        }

    }


    @media (min-width: ${bp(4)}) {
        
        .mainLayout-smallLogo{
            display: none;
        }

    }


`