import { createSlice, PayloadAction, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import { RootState} from 'store/index'

import { refreshToken, refreshTokenPending, refreshTokenFulfilled, refreshTokenRejected } from './refreshToken'


type AuthState = {
    isInitialized: boolean,
    exp: number | null,
    isAuthenticated: boolean,
    isAwaitingLogin: boolean,
    isAwaitingTokenRefresh: boolean,
    persist: boolean,
    user?: User
}

type SetTokensInput = {
    accessToken: string,
    refreshToken: string
}



// Get and fill initialstate
const localAccessToken = localStorage.getItem('access-token')
const localRefreshToken = localStorage.getItem('refresh-token')

let initialState: AuthState

if(localAccessToken && localRefreshToken){

    const accessTokenParts = localAccessToken.split('.')
    const accessTokenBody = accessTokenParts[1]
    const decodedAccessToken = JSON.parse(window.atob(accessTokenBody))

    const tokenUser: User = decodedAccessToken?.data?.user
    const persist = decodedAccessToken?.data?.persist ?? false

    initialState = {
        isInitialized: true,
        exp: decodedAccessToken.exp ?? null,
        isAuthenticated: true,
        isAwaitingLogin: false,
        isAwaitingTokenRefresh: false,
        persist,
        user: tokenUser ?? null
    }

}
else{
    initialState = {
        isInitialized: false,
        exp: null,
        isAuthenticated: false,
        isAwaitingLogin: false,
        isAwaitingTokenRefresh: false,
        persist: false
    }
}


// Get Current Timestamp
const getCurrentTimestamp = () => {
    return Math.floor(new Date().getTime() / 1000)
}


// Log Out
const logout = createAsyncThunk(
    'auth/logout',
    async (payload: undefined, thunkAPI) => {

        await localStorage.removeItem('access-token')
        await localStorage.removeItem('refresh-token')

    }
)

// Set Tokens
const setTokens = createAsyncThunk(
    'auth/setTokens',
    async (payload: SetTokensInput, thunkAPI) => {

        const tokenParts = payload.accessToken.split('.')
        const refreshTokenParts = payload.refreshToken.split('.')

        const accessTokenBody = [tokenParts[0], tokenParts[1]].join('.')
        const refreshTokenBody = [refreshTokenParts[0], refreshTokenParts[1]].join('.')

        await localStorage.setItem('access-token', accessTokenBody)
        await localStorage.setItem('refresh-token', refreshTokenBody)

        return payload

    }
)


  

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {

        set: (state: AuthState, action: PayloadAction<AuthState>) => {
            return {...action.payload}
        },

        setExpiry: (state: AuthState, action: PayloadAction<number>) => {
            state.exp = action.payload
        },

        setIsAwaitingLogin: (state: AuthState, action: PayloadAction<boolean>) => {
            
            state.isAwaitingLogin = action.payload

            if(action.payload){
                state.isAuthenticated = false
            }
            
        },

        setIsAwaitingTokenRefresh: (state: AuthState, action: PayloadAction<boolean>) => {
            state.isAwaitingTokenRefresh = action.payload
        },

        setUser: (state: AuthState, action: PayloadAction<User>) => {
            state.user = action.payload
        }

    },
    extraReducers: (builder) => {

        // Log Out
        builder.addCase(logout.fulfilled, (state: AuthState, action) => {
            return {...initialState, isAwaitingLogin: true}
        })

        // Refresh Token
        builder.addCase(refreshToken.pending, refreshTokenPending)
        builder.addCase(refreshToken.fulfilled, refreshTokenFulfilled)
        builder.addCase(refreshToken.rejected, refreshTokenRejected)


        // Set Tokens
        builder.addCase(setTokens.fulfilled, (state: AuthState, action) => {

            // Decode the tokens
            const accessToken = action.payload.accessToken
            const accessTokenParts = accessToken.split('.')
            const accessTokenBody = accessTokenParts[1]
            const decodedAccessToken = JSON.parse(window.atob(accessTokenBody))

            // Create the user
            const tokenUser: User = decodedAccessToken.data?.user

            const newUser: User = {
                user_id: tokenUser.user_id,
                first_name: tokenUser.first_name,
                last_name: tokenUser.last_name,
                email_address: tokenUser.email_address,
                company_name: tokenUser.company_name,
                is_admin: tokenUser.is_admin
            }

            // Set the state
            state.isInitialized = true
            state.isAuthenticated = true
            state.exp = decodedAccessToken.exp
            state.persist = decodedAccessToken.data?.persist ?? false
            state.user = newUser

        })

    }
})



export default authSlice

export const {
    set,
    setExpiry,
    setIsAwaitingLogin,
    setIsAwaitingTokenRefresh,
    setUser
} = authSlice.actions


const getAwaitingLogin = (state: RootState) => state.auth.isAwaitingLogin
const getAwaitingTokenRefresh = (state: RootState) => state.auth.isAwaitingTokenRefresh
const getUser = (state: RootState) => state.auth.user

const selectAuth = (state: RootState) => state.auth
const selectAuthIsAdmin = (state: RootState) => state.auth.user?.is_admin ?? false
const selectAuthIsAuthenticated = (state: RootState) => state.auth.isAuthenticated ?? false

const selectAuthIsAwaitingLogin = createSelector(
    [ getAwaitingLogin ],
    (awaitingLogin: boolean) => awaitingLogin
)

const selectAuthIsAwaitingTokenRefresh = createSelector(
    [ getAwaitingTokenRefresh ],
    (awaitingTokenRefresh: boolean) => awaitingTokenRefresh
)

const selectAuthUser = createSelector(
    [ getUser ],
    (user: User | undefined) => user
)


const selectAuthPersist = (state: RootState) => state.auth.persist ?? false

const selectAuthTokenIsValid = (state: RootState) => {

    const currentTimestamp = getCurrentTimestamp()

    if(state.auth.isAuthenticated && state.auth.exp && currentTimestamp < state.auth.exp){
        return true
    }
    else{
        return false
    }

}


const selectAuthTokenTimeUntilExpiry = (state: RootState) => {

    const currentTimestamp = getCurrentTimestamp()

    if(state.auth.isAuthenticated && state.auth.exp && currentTimestamp < state.auth.exp){
        return state.auth.exp - currentTimestamp
    }
    else{
        return 0
    }

}

export type { AuthState }

export {

    selectAuth,
    selectAuthIsAdmin,
    selectAuthIsAuthenticated,
    selectAuthIsAwaitingLogin,
    selectAuthIsAwaitingTokenRefresh,
    selectAuthPersist,
    selectAuthTokenTimeUntilExpiry,
    selectAuthTokenIsValid,
    selectAuthUser,

    refreshToken,
    logout,
    setTokens

}
