import React from 'react'

import ResetStyles from 'styles/reset'
import LayoutStyles from 'styles/layout'
import MainStyles from 'styles/main'
import ModalStyles from 'styles/modals'
import TypographyStyles from 'styles/typography'


const GlobalStyles = () => {

    return (
        <>
            <ResetStyles />
            <LayoutStyles />
            <MainStyles />
            <ModalStyles />
            <TypographyStyles />
        </>
    )

}


export default GlobalStyles
