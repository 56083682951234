import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import { RootState} from 'store/index'



type BasketState = {
    isInitialized: boolean,
    order?: Order
}

let initialState: BasketState = {
    isInitialized: false
}
  

const basketSlice = createSlice({
	name: 'basket',
	initialState,
	reducers: {
        
        addUpdateBasketOrderItem: (state: BasketState, action: PayloadAction<OrderItem>) => {
            
            if(state.order === undefined){return}

            if(state.order.related?.order_items === undefined){
                state.order.related = state.order.related ?? {}
                state.order.related.order_items = []
            }

            let orderItems = state.order?.related?.order_items
            let matchingRow = orderItems.find(item => item.mm_web_st_code === action.payload.mm_web_st_code)

            if(matchingRow){

                orderItems = orderItems.map(item => {

                    if(item.mm_web_st_code === action.payload.mm_web_st_code){
                        item.quantity = action.payload.quantity
                    }

                    return item

                })

            }
            else{
                orderItems.push(action.payload)
            }

            if(state.order?.related?.order_items){
                state.order.related.order_items = orderItems
            }
            

        },

        deleteBasketOrderItem: (state: BasketState, action: PayloadAction<number>) => {
            
            if(state.order === undefined){return}

            if(state.order.related?.order_items === undefined){
                state.order.related = state.order.related ?? {}
                state.order.related.order_items = []
            }

            let orderItems = state.order?.related?.order_items

            orderItems = orderItems.filter(item => item.order_item_id !== action.payload)

            if(state.order?.related?.order_items){
                state.order.related.order_items = [...orderItems]
            }

        },

        reset: (state: BasketState, action: PayloadAction) => {
            return {...initialState, isInitialized: state.isInitialized}
        },

        set: (state: BasketState, action: PayloadAction<BasketState>) => {
            return {...action.payload}
        },

        setIsInitialized: (state: BasketState, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload
        },

        setOrder: (state: BasketState, action: PayloadAction<Order>) => {
            state.order = {...action.payload}
        }

    }
    
})



export default basketSlice

export const {
    addUpdateBasketOrderItem,
    deleteBasketOrderItem,
    reset,
    set,
    setIsInitialized,
    setOrder
} = basketSlice.actions


const getIsInitialized = (state: RootState) => state.basket.isInitialized
const getMmCustomerCode = (state: RootState) => state.basket.order?.mm_cus_code
const getOrderId = (state: RootState) => state.basket.order?.order_id

const getOrderItems = (state: RootState) => state.basket.order?.related?.order_items

const selectBasketIsInitialized = createSelector(
    [ getIsInitialized ],
    (isInitialized: boolean) => isInitialized
)

const selectBasketMmCustCode = createSelector(
    [ getMmCustomerCode ],
    (mm_cus_code: string | undefined) => mm_cus_code
)

const selectBasketOrderId = createSelector(
    [ getOrderId ],
    (orderId: number | undefined) => orderId
)

const selectBasketItemCount = createSelector(
    [ getOrderItems ],
    (orderItems: OrderItem[] | undefined) => {
        if(orderItems && orderItems.length > 0){
            let count = orderItems.reduce((total, currentVal, ) => total + currentVal.quantity, 0)
            return count
        }
        else{
            return 0
        }
    }
)

export type { BasketState }

export {
    selectBasketIsInitialized,
    selectBasketItemCount,
    selectBasketMmCustCode,
    selectBasketOrderId
}