import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'

import { RootState } from '../index'
import { AuthState, setTokens } from './index'

import config from 'settings/config'


// RefreshToken
const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (payload: undefined, thunkAPI) => {

        const refreshTokenBody =  await localStorage.getItem('refresh-token')
        const state = thunkAPI.getState() as RootState
        const { persist } = state.auth

        if(!refreshTokenBody){
            thunkAPI.rejectWithValue('refresh_token_not_found')
        }

        let result = await axios.post(`${config.apiUrl}/auth/refresh-token`, {persist}, {
            headers: {
                "refresh-token": refreshTokenBody
            },
            withCredentials: true
        })

        const responseHeaders = result.headers

        if(responseHeaders){
            await thunkAPI.dispatch(setTokens({accessToken: responseHeaders['access-token'], refreshToken: responseHeaders['refresh-token']}))
        }
        else{
            thunkAPI.rejectWithValue('refresh_token_not_found')
        }

        return 'success'
  
    }

)


const refreshTokenPending = (state: AuthState, action) => {
    state.isAwaitingTokenRefresh = true
}

const refreshTokenFulfilled = (state: AuthState, action) => {
    state.isAwaitingTokenRefresh = false
    state.isAwaitingLogin = false 
}

const refreshTokenRejected = (state: AuthState, action) => { 
    state.isAwaitingTokenRefresh = false
    state.isAwaitingLogin = true 
}



export { 
    refreshToken,
    refreshTokenPending,
    refreshTokenFulfilled,
    refreshTokenRejected
}