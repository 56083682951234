import { createGlobalStyle } from 'styled-components'

// Layout Styles
const LayoutStyles = createGlobalStyle`

    html {
        height: 100%;
    }

    body {
        min-height: 100%;
    }

    #root{
        height: 100%;
    }

`

export default LayoutStyles

