import { createSlice, PayloadAction } from "@reduxjs/toolkit"


type MasterState = {
    drawerMenuIsOpen: boolean,
    mainMenuIsCollapsed: boolean,
    activeMenuItem: string
}

let initialState: MasterState = {
    drawerMenuIsOpen: false,
    mainMenuIsCollapsed: false,
    activeMenuItem: ''
}


const masterSlice = createSlice({
	name: 'master',
	initialState,
	reducers: {

        setDrawerMenuIsOpen: (state: MasterState, action: PayloadAction<boolean>) => {
            state.drawerMenuIsOpen = action.payload
        },

        setMainMenuIsCollapsed: (state: MasterState, action: PayloadAction<boolean>) => {
            state.mainMenuIsCollapsed = action.payload
        },

        setActiveMenuItem: (state: MasterState, action: PayloadAction<string>) => {
            state.activeMenuItem = action.payload
        }

    }
})

export default masterSlice

export const {
    setDrawerMenuIsOpen,
    setMainMenuIsCollapsed,
    setActiveMenuItem
} = masterSlice.actions


// Selectors

const selectDrawerMenuIsOpen = state => state.master.drawerMenuIsOpen
const selectMainMenuIsCollapsed = state => state.master.mainMenuIsCollapsed
const selectActiveMenuItem = state => state.master.activeMenuItem


export {
    selectDrawerMenuIsOpen,
    selectMainMenuIsCollapsed,
    selectActiveMenuItem
}
