import { createSlice, PayloadAction } from "@reduxjs/toolkit"


type UpdateCounts = {
    screen?: boolean
} | undefined

type LoadingState = {
    loadingCount: number,
    loadingScreenCount: number
}

let initialState: LoadingState = {
    loadingCount: 0,
    loadingScreenCount: 0
}


const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {

        increment: (state: LoadingState, action: PayloadAction<UpdateCounts>) => {

            state.loadingCount++

            if(action.payload?.screen){
                state.loadingScreenCount ++
            }

        },

        decrement: (state: LoadingState, action: PayloadAction<UpdateCounts>) => {

            state.loadingCount--

            if(action.payload?.screen){
                state.loadingScreenCount --
            }

        }

    }
})

export default loadingSlice

export const {
    increment,
    decrement
} = loadingSlice.actions


// Selectors
const selectIsLoading = state => state.loading.loadingCount > 0
const selectLoadingCount = state => state.loading.loadingCount
const selectLoadingScreenCount = state => state.loading.loadingScreenCount

export {
    selectIsLoading,
    selectLoadingCount,
	selectLoadingScreenCount
}
