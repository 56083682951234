import { useEffect, useRef, useReducer, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {AxiosResponse, AxiosError} from 'axios'

import loadingStore  from 'store/loading'
import authStore, {
    selectAuthTokenTimeUntilExpiry, 
    selectAuthIsAwaitingLogin, 
    selectAuthIsAwaitingTokenRefresh, 
    selectAuthPersist, 
    refreshToken
}  from 'store/auth'


let initialState: UseApiState = {
    asyncFn: null,
    callConfig: null,
    callData: null,
    countCompletedCalls: 0,
    countFailedCalls: 0,
    countPendingCalls: 0,
    countStartedCalls: 0,
    countSuccessfulCalls: 0,
    responseHeaders: null,
    data: null,
    meta: null,
    error: null,
    isAwaitingCall: false,
    mode: 'function',
    requiresAuth: false,
    status: 'initial',
    statusCode: null
}


// Reducer
type Reducer = (state: UseApiState, action: any) => UseApiState

const  reducer: Reducer = (state, action) => {

    switch (action.type) {

        case 'DEC_PENDING_CALLS':
            return {...state, countPendingCalls: Math.max(state.countPendingCalls - 1, 0)}

        case 'INC_COMPLETED_CALLS':
            return {...state, countCompletedCalls: state.countCompletedCalls + 1}

        case 'INC_FAILED_CALLS':
            return {...state, countFailedCalls: state.countFailedCalls + 1}

        case 'INC_PENDING_CALLS':
            return {...state, countPendingCalls: state.countPendingCalls + 1, status: 'pending'}

        case 'INC_STARTED_CALLS':
            return {...state, countStartedCalls: state.countStartedCalls + 1}

        case 'INC_SUCCESSFUL_CALLS':
            return {...state, countSuccessfulCalls: state.countSuccessfulCalls + 1}

        case 'REQUEST_CALL':
            return {...state, isAwaitingCall: true}

        case 'SET_CALL_CONFIG':
            return {...state, callConfig: action.payload}

        case 'SET_CALL_DATA':
            return {...state, callData: action.payload}

        case 'SET_DATA':
            return {...state, data: action.payload}

        case 'SET_ERROR':
            return {...state, error: action.payload}

        case 'SET_IS_AWAITING_CALL':
            return {...state, isAwaitingCall: action.payload}

        case 'SET_META':
            return {...state, meta: action.payload}

        case 'SET_RESPONSE_HEADERS':
            return {...state, responseHeaders: action.payload}

        case 'SET_STATUS':
            return {...state, status: action.payload}

        case 'SET_STATUS_CODE':
            return {...state, statusCode: action.payload}

        default:
            throw new Error()

    }

}

const useApi = (options: UseApiOptions): UseApiReturn => {

    const counter = useRef(0)

    type MergeState = Partial<UseApiState>

    const reduxDispatch = useDispatch()
    const authTokenTimeUntilExpiry = useSelector(selectAuthTokenTimeUntilExpiry)
    const authPersist = useSelector(selectAuthPersist)
    const authIsAwaitingLogin = useSelector(selectAuthIsAwaitingLogin)
    const authIsAwaitingTokenRefresh = useSelector(selectAuthIsAwaitingTokenRefresh)

    

    const stateOptions: MergeState = {
        asyncFn: options.asyncFn ?? null,
        requiresAuth: options.requiresAuth ?? false
    }

    if(options.defer !== true){
        stateOptions.isAwaitingCall = true
    }

    const [state, dispatch] = useReducer(reducer, {...initialState, ...stateOptions});


    // const notInitial = useMemo(() => {

    //     let notInitial = state?.countStartedCalls > 0
        
    //     console.log("notInitial", notInitial)

    //     return notInitial

    // }, [state.countPendingCalls])


    // Do the call when the isAwaitingCall flag is set
    useEffect(() => {

        if(state.isAwaitingCall !== true || (state.mode === 'function' && state.asyncFn === null)){return}

        async function doCall() {

            // Update counters and loading indicators
            counter.current += 1
            dispatch({type: 'SET_IS_AWAITING_CALL', payload: false})
            dispatch({type: 'INC_STARTED_CALLS'})
            dispatch({type: 'INC_PENDING_CALLS'})

            reduxDispatch(loadingStore.actions.increment())

            // Get the call config so we can add to it
            let callConfig = state.callConfig

            // The call above to increment the started count hasn't happened yet so we add 1
            let callCount = state.countStartedCalls + 1


            // If the call requires auth then we need to see if they are currently logged in
            if(state.requiresAuth){

                console.log("authTokenTimeUntilExpiry", authTokenTimeUntilExpiry)

                // If the token doesn't exist or has expired then refresh token or kick to login depending on persist 
                if(!authTokenTimeUntilExpiry || authTokenTimeUntilExpiry <= 0){

                    console.log("Token missing or expired")

                    if(authPersist){

                        console.log("authPersist is set")

                        if(!authIsAwaitingTokenRefresh){

                            console.log("Dispatching refresh token")

                            reduxDispatch(refreshToken())
                        }
                    }
                    else{

                        console.log("Dispatching login request")

                        reduxDispatch(authStore.actions.setIsAwaitingLogin(true))
                    }
                    
                    return // Becuase we don't want to continue doing the call
                }

                // If the token has nearly run out then continue with the call but ask for a new token anyway
                // if(authTokenTimeUntilExpiry <= 5){
                //     reduxDispatch(refreshToken())
                // }

                // Add auth stuff to call config
                callConfig = {...callConfig, withCredentials: true}

                const accessTokenBody = await localStorage.getItem('access-token')
                const existingHeaders: ObjectOfStrings = (callConfig.headers as ObjectOfStrings) || {}
                const headers = {...existingHeaders, 'access-token': accessTokenBody}

                callConfig.headers = headers

            }


            let result: AxiosResponse

            try {

                result = await state.asyncFn!(callConfig, state.callData) // ! = Typescript non-null assertion operator

                if(callCount === counter.current){
                    
                    dispatch({type: 'SET_RESPONSE_HEADERS', payload: result.headers ?? null})
                    dispatch({type: 'SET_META', payload: result.data?.meta ?? null})
                    dispatch({type: 'SET_DATA', payload: result.data?.data ?? null})
                    dispatch({type: 'SET_ERROR', payload: null})

                    dispatch({type: 'SET_CALL_CONFIG', payload: null})
                    dispatch({type: 'SET_CALL_DATA', payload: null})

                    dispatch({type: 'DEC_PENDING_CALLS'})
                    dispatch({type: 'INC_COMPLETED_CALLS'})
                    dispatch({type: 'INC_SUCCESSFUL_CALLS'})
                    
                    dispatch({type: 'SET_STATUS', payload: 'success'})
                    dispatch({type: 'SET_STATUS_CODE', payload: result.status})

                }
                else{
                    
                    // TODO - What happens if this isn't the most recent call?

                }

            } catch (error) {

                const axiosError: AxiosError = error as AxiosError

                // If the user required auth and wasn't logged in we need to store the request
                if(state.requiresAuth && axiosError.response?.status === 401){

                    if(!authIsAwaitingLogin && !authIsAwaitingTokenRefresh){
                        reduxDispatch(authStore.actions.setIsAwaitingLogin(true))
                    }
                    
                    return

                } 

                // Otherwise we need to set errors and update statuses
                else{

                    const response = axiosError.response
                    const data = response?.data

                    const newError: ApiError = {
                        status: response?.status ?? 418,
                        code: data?.error?.code,
                        message: data?.error?.message,
                        originalError: data?.error?.originalError,
                        fieldErrors: data?.error?.fieldErrors,
                        data: data?.error?.data,
                    }

                    dispatch({type: 'SET_RESPONSE_HEADERS', payload: null})
                    dispatch({type: 'SET_META', payload: null})
                    dispatch({type: 'SET_DATA', payload: null})
                    dispatch({type: 'SET_ERROR', payload: newError})

                    dispatch({type: 'DEC_PENDING_CALLS'})
                    dispatch({type: 'INC_COMPLETED_CALLS'})
                    dispatch({type: 'INC_FAILED_CALLS'})

                    dispatch({type: 'SET_STATUS', payload: 'failed'})
                    dispatch({type: 'SET_STATUS_CODE', payload: axiosError.response?.status})

                }

            }

            reduxDispatch(loadingStore.actions.decrement())
            
        }

        doCall()
        
    }, [state.isAwaitingCall, state.asyncFn, state.mode, reduxDispatch, authIsAwaitingLogin, authIsAwaitingTokenRefresh, state.requiresAuth, authPersist, authTokenTimeUntilExpiry, state.callConfig, state.callData, state.countStartedCalls])



    // When the user logs in or gets a new token, rerun the call if it's pending
    useEffect(() => {

        if(state.requiresAuth && authIsAwaitingTokenRefresh === false && authIsAwaitingLogin === false && state.countPendingCalls > 0){

            reduxDispatch(loadingStore.actions.decrement())
            dispatch({type: 'DEC_PENDING_CALLS'})
            dispatch({type: 'REQUEST_CALL'})
        }

    }, [authIsAwaitingTokenRefresh, authIsAwaitingLogin, reduxDispatch, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps


    // Unmount
    useEffect(() => {

        return (() => {
            // Unmount here
        })

    }, [])




    // Create the call function
    const call: UseApiCallFunction = useCallback(({config, data} = {}) => {
        dispatch({type: 'SET_CALL_CONFIG', payload: config})
        dispatch({type: 'SET_CALL_DATA', payload: data})
        dispatch({type: 'REQUEST_CALL'})
    }, [dispatch])

    return {
        call,
        countCompletedCalls: state.countCompletedCalls,
        countFailedCalls: state.countFailedCalls,
        countPendingCalls: state.countPendingCalls,
        countStartedCalls: state.countStartedCalls,
        countSuccessfulCalls: state.countSuccessfulCalls,
        responseHeaders: state.responseHeaders,
        meta: state.meta,
        data: state.data,
        error: state.error,
        isError: state.status === 'failed',
        isPending: state.status === 'pending',
        status: state.status,
        statusCode: state.statusCode
    }

}


export default useApi
