import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import masterStore, { selectMainMenuIsCollapsed }  from 'store/master'
import { selectIsLoading }  from 'store/loading'
import { logout }  from 'store/auth'
import { selectBasketItemCount }  from 'store/basket'

import theme, {bp} from 'settings/theme'

import { Menu, Grid, Avatar, Dropdown, Badge } from 'antd'

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons'

import {
    Pulse
} from 'better-react-spinkit'


const { useBreakpoint } = Grid


type Props = {}

type StyledProps = {}


const Component: React.FC<Props> = (props) => {

    const screens = useBreakpoint()
    const dispatch = useDispatch()
    const mainMenuIsCollapsed = useSelector(selectMainMenuIsCollapsed)
    const masterIsLoading = useSelector(selectIsLoading)
    const basketItemCount = useSelector(selectBasketItemCount)

    return (
        <StyledComponent className="header">

            <div className="header-left">

                {!screens.md &&
                    <>
                        <Link to={`/`}>
                            <button className="header-smallLogo">
                                <div className="header-smallLogo-inner">
                                    <img src="/logo.png" alt="logo" />
                                </div>
                            </button>
                        </Link>
                        
                        <button 
                            onClick={() => dispatch(masterStore.actions.setDrawerMenuIsOpen(true))}
                            className="header-item header-menuToggle"
                        >
                            <div className="header-item-inner">
                                <MenuUnfoldOutlined />
                            </div>
                        </button>
                    </>
                    
                }

                {screens.lg &&
                    <button 
                        onClick={() => dispatch(masterStore.actions.setMainMenuIsCollapsed(!mainMenuIsCollapsed))}
                        className="header-item header-menuToggle" 
                    >
                        <div className="header-item-inner">
                            {!mainMenuIsCollapsed && <MenuFoldOutlined />}
                            {mainMenuIsCollapsed && <MenuUnfoldOutlined />}
                        </div>
                    </button>
                }

                {masterIsLoading && 
                    <div className="header-item">
                        <Pulse color={theme.colors.brand} size={screens.md ? 40 : 32} />
                    </div>
                } 

            </div>

            <div className="header-right">

                <Link to={`/basket`}>
                    <button className="header-item header-basketButton">
                        <div className="header-item-inner">
                            {!!basketItemCount && basketItemCount > 0 &&
                                <Badge count={basketItemCount}>
                                    <ShoppingCartOutlined />
                                </Badge>
                            }
                            {(!basketItemCount || basketItemCount === 0) &&
                                <ShoppingCartOutlined />
                            }
                        </div>
                    </button>
                </Link>
                
                <Dropdown overlay={<AccountMenu />} placement="bottomLeft">
                    <div className="header-avatar">
                        <Avatar size={screens.md ? 48 : 40} style={{ backgroundColor: 'rgba(117, 88, 167, 0.8)' }} icon={<UserOutlined />} />
                    </div>
                </Dropdown>
                
            </div>
            
        </StyledComponent>
    )

}


type AccountMenuProps = {}

const AccountMenu: React.FC<AccountMenuProps> = (props) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const logOut = () => {
        dispatch(logout())
        history.push('/')
    }

    return (
        <StyledAccountMenu className="accountMenu">

            <Menu>
                <Menu.Item>
                    <Link to={`/account`}>
                        Account Details
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={`/change-password`}>
                        Change Your Password
                    </Link>
                </Menu.Item>
                <Menu.Item onClick={logOut}>
                    Log Out
                </Menu.Item>
            </Menu>

        </StyledAccountMenu>
    )

}


export default Component





const StyledComponent = styled.header<StyledProps>`

    flex: 0 0 auto;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
    background-color: white;

    .header-left{
        display: flex;
    }

    .header-right{
        display: flex;
    }

    .header-smallLogo{

        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 60px;
        height: 60px;
        padding-right: 6px;

        .header-smallLogo-inner{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 3px;
            background-color: rgba(142, 198, 65, 1);
        }

        img{
            width: 30px;
        }

        &:hover, &:focus{
            filter: brightness(120%);
        }

    }


    .header-item{

        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;

        .header-item-inner{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
        }

    }


    .header-menuToggle {

        cursor: pointer;
        margin-left: 10px;
        transition: all 0.15s ease-out;

        .header-item-inner{
            border-radius: 50%;
            background-color: rgba(0,0,0,0);
            transition: all 0.15s ease-out;
        }

        svg{
            width: 16px;
            height: 16px;
            fill: rgba(0,0,0,0.5);
            transition: all 0.15s ease-out;
        }

        &:hover, &:focus{

            .header-item-inner{
                background-color: rgba(0,0,0,0.05);
            }

            svg{
                fill: rgba(0,0,0,0.8);
            }

        }

    }


    .header-basketButton{

        cursor: pointer;
        margin-right: 10px;
        transition: all 0.15s ease-out;

        .header-item-inner{
            
            border-radius: 50%;
            background-color: rgba(0,0,0,0);
            transition: all 0.15s ease-out;

            sup{
                top: -3px;
                right: -3px;
            }

        }
        
        svg{
            width: 20px;
            height: 20px;
            fill: rgba(0,0,0,0.5);
            transition: all 0.15s ease-out;
        }

        &:hover, &:focus{

            .header-item-inner{
                background-color: rgba(0,0,0,0.05);
            }

            svg{
                fill: rgba(0,0,0,0.8);
            }

        }

    }


    .header-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 100%;
    }


    @media (min-width: ${bp(4)}) {

        height: 72px;

        .header-avatar{
            width: 72px;
        }

        .header-item{

            width: 48px;

            .header-item-inner{
                width: 48px;
                height: 48px;
            }

        }
        

    }


`

const StyledAccountMenu = styled.div`

    box-shadow: 0 2px 5px rgba(0,0,0,0.1);

    .ant-dropdown-menu-item, 
    .ant-dropdown-menu-submenu-title{

        padding: 12px;

    }


`