import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { Modal, Button, Form, Input, Checkbox } from 'antd'

import config from 'settings/config'

import LoaderCover from 'components/loaderCover'

import AuthStore, {selectAuthIsAwaitingLogin, setTokens} from 'store/auth'

import useApi  from 'hooks/useApi'

type Props = {}


const Component: React.FC<Props> = () => {

    const dispatch = useDispatch()

    const visible = useSelector(selectAuthIsAwaitingLogin)

    const [form] = Form.useForm()

    // Set up call to API
    const { 
        call: callLogin,
        isPending: loginIsPending,
        responseHeaders: loginResponseHeaders,
        status: loginStatus
    } = useApi({ 
        asyncFn: (callConfig, callData) => axios.post(`${config.apiUrl}/auth/login`, callData, callConfig),
        defer: true
    })


    // Call was a success
    useEffect(() => {

        if(loginStatus === 'success'){
        
            let responseHeaders = loginResponseHeaders
            dispatch(setTokens({accessToken: responseHeaders['access-token'], refreshToken: responseHeaders['refresh-token']}))
            setTimeout(() => dispatch(AuthStore.actions.setIsAwaitingLogin(false)), 100)

        }


        if(loginStatus === 'failed'){
            // TODO - Need to show a message to the user to say that their login failed
        }


    }, [loginStatus, loginResponseHeaders, dispatch])



    // Form is submitted and valid
    const onFinish = values => {
        callLogin({data: values, config: {withCredentials: true}})
    }

    const handleSubmitButtonClick = () => {
        form.submit()
    }

    return (
            
        <StyledComponent
            title="Welcome to Multiple Marketing"
            visible={visible}
            centered={true}
            closable={false}
            destroyOnClose={true}
            footer={(
                <div className="loginModal-footer">
                    
                    <Link to="/reset-password">
                        <Button shape="round" size="large" className="rm-button rm-button--ghost rm-button--primary">
                            I forgot my password
                        </Button>
                    </Link>

                    <Button type="primary" onClick={handleSubmitButtonClick} shape="round" size="large" className="rm-button rm-button--primary">
                        Log In
                    </Button>

                </div>
            )}
            modalRender={modal => (
                <div className="loginModal-wrapper">
                    {loginIsPending && <LoaderCover style={{zIndex: 2}} />}
                    <div className="loginModal-main" style={{zIndex: 1}}>
                        {modal}
                    </div>
                </div>
            )}
            className="loginModal"
            maskStyle={{backgroundColor: 'rgba(44, 44, 44, 1)'}}
        >

            {/* Intro  */}
            <div className="loginModal-intro">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.</p>
            </div>

            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                preserve={false}
                onFinish={onFinish}
            >
                
                {/* Email Address */}
                <Form.Item 
                    name="email_address" 
                    label="Your Email Address" 
                    className="formRow" 
                    rules={[
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        }
                    ]}
                >
                    <Input placeholder="e.g. john@smith.com" size="large" />
                </Form.Item>

                {/* Password */}
                <Form.Item 
                    name="password" 
                    label="Your Password" 
                    className="formRow" 
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password',
                        }
                    ]}
                >
                    <Input.Password placeholder="********" size="large" />
                </Form.Item>

                {/* Persist */}
                <Form.Item name="persist" valuePropName="checked" noStyle>
                    <Checkbox>Keep me logged in</Checkbox>
                </Form.Item>

            </Form>


        </StyledComponent>
    )

}


export default Component




const StyledComponent = styled(Modal)`

    width:320px;
    max-width:87.5%;

    .loginModal-wrapper{
        position: relative;
    }

    .ant-modal-header{
        padding: 32px 24px 24px 24px;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        background-color: transparent;
    }

    .ant-modal-content{
        
    }

    .ant-modal-footer{
        padding: 16px;
    }


    .loginModal-intro{
        margin-bottom: 2rem;
    }

    .loginModal-footer{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

`